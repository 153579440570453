<template>
  <div class="users-wrapper page">
    <data-table
      :data="renderData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/dataTable'

export default {
  name: 'Products',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters('users', ['getData']),
    ...mapGetters('settings', ['getDefaultValues']),
    renderData () {
      return this.getData.map(item => {
        return {
          ...item,
          role: this.getListNamesRole(item.role),
        }
      })
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('users', { loadDataUsers: 'loadData' }),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataUsers()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    getListNamesRole (role) {
      return role.reduce((acc, roleCode) => {
        const name = this.getDefaultValues?.role?.filter(({ code }) => code === roleCode)[0]?.name

        acc += `${name} `

        return acc
      }, '') || []
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />